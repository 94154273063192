import React from 'react';
import './pagina-de-acesso.css';
import Seo from '../components/Seo';
import Layout from '../components/layout';

const AccessPage = () => {
    return (
        <Layout>
            <div className="access-page-container">
                <Seo
                    title="Escolha o Sistema - Prontuário Fácil ou Plantão Fácil"
                    description="Selecione o sistema que deseja acessar: Prontuário Fácil para gestão de clínicas ou Plantão Fácil para gestão de plantões médicos."
                />
                <main className="access-page-content">
                    <h1>Escolha o Sistema</h1>
                    <div className="access-page-options">
                        <div className="access-page-option">
                            <h2>Plantão Fácil</h2>
                            <p>Gestão simplificada de plantões e recebíveis médicos.</p>
                            <a href="https://plantao.prontuariofacil.com.br" className="access-page-button">Acessar Plantão Fácil</a>
                        </div>
                        <div className="access-page-option">
                            <h2>Prontuário Fácil</h2>
                            <p>Gestão completa para clínicas e consultórios médicos.</p>
                            <a href="https://prontuariofacil.com.br/login" className="access-page-button">Acessar Prontuário Fácil</a>
                        </div>
                    </div>
                </main>
            </div>
        </Layout>
    );
};

export default AccessPage;
